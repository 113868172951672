import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import '../Page.css';
/* Pullstate Store */
import { UserStore } from "../../store/UserStore";
import OnboardingModal from './OnboardingModal';

const Onboarding = () => {

    const startOnboaring = () => {
        UserStore.update(s => {
            s.onboardingStep = 1;
        })
    }

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <h2 className="headingText ion-text-center">Welcome to FirePermit.Online</h2>
                                      
                    <p className="ion-text-center">
                        <IonButton 
                            onClick={startOnboaring}
                            shape="round"
                            >
                            Complete My Profile
                        </IonButton>
                    </p>
                    <p className="ion-text-center">Before you can submit an burn permit application, you must complete your profile.</p>
                </IonCol>
            </IonRow>
            <OnboardingModal />
        </IonGrid>
  );
};

export default Onboarding;
