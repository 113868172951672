import React, { useState } from 'react';
import '../../Page.css';
import AddressPicker from '../../AddressPicker';
import { IonButton, IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';
import { UserStore } from "../../../store/UserStore";
import {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";
import { PermitStore } from '../../../store/PermitStore';
const Step3 = () => {
    const step = PermitStore.useState(s => s.applicationStep);
    const application = PermitStore.useState(s => s.application);
    const userDoc = UserStore.useState(s => s.userDoc);
    const [ showAddressPicker, setShowAddressPicker ] = useState(false);

    // useEffect(() => {
    //     if(application.address && application.address !== 'other') {
    //         PermitStore.update(s => {
    //             s.application = {...s.application, otherAddress: null};
    //           });
    //     }
    // }, [application.address]);

    const getCoords = (address) => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            PermitStore.update(s => {
              s.application = {...s.application, latitude: lat, longitude: lng};
            });
          }
        );
      }
    const handleChange = (val) => {
        // console.log(val)
        if(val === 'other') {
            PermitStore.update(s => {
                s.application = {...s.application, address: val};
            });
            setShowAddressPicker(true);
        } 
        if(val !== 'other') {
            setShowAddressPicker(false);
            getCoords(val);
            PermitStore.update(s => {
                s.application = {...s.application, otherAddress: null, address: userDoc.fullAddress};
            });
        }
    }
    return (
        <div className={ step === 3 ? 'steps' : 'ion-hide'}>
            <h2 className="ion-padding-horizontal onboardingTitles">Choose Address</h2>
            <div style={{height: '40px'}} />
            <IonRadioGroup  
                mode="md"
                aria-label={'List of burn permits'}
                value={application.address} 
                onIonChange={e => {
                    handleChange(e.target.value)
                }}
            >
                <IonItem aria-label={'address'}>
                    <IonLabel className="ion-text-wrap">
                        {userDoc.fullAddress}
                    </IonLabel>
                    <IonRadio slot="start" value={userDoc.fullAddress} />
                </IonItem>
                <IonItem aria-label={'address'}>
                    <IonLabel className="ion-text-wrap">
                        Use a different address
                    </IonLabel>
                    <IonRadio slot="start" value={'other'} />
                </IonItem>
            </IonRadioGroup>
            {showAddressPicker ? 
                <div className="ion-padding">
                    <AddressPicker context="application" />
                </div>
            : null }
            <IonButton  
                disabled={
                    application.address === null ? true :
                    application.address === 'other' && application.otherAddress === null ? true :
                    false}
                className="ion-margin" 
                shape="round" 
                expand="block"
                onClick={() => {
                PermitStore.update(s => {
                    s.applicationStep = 4;
                })
                }}
                >Continue</IonButton>
        </div>
        
    );
};

export default Step3;
