import { IonButton, IonGrid, IonRow, IonCol, IonLoading } from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from "../store/UserStore";
import { PermitStore } from '../store/PermitStore';

import ApplicationModal from './Application/ApplicationModal';


const Start = () => {
    const userDoc = UserStore.useState(s => s.userDoc);
    const permitTypes = PermitStore.useState(s => s.permitTypes);
    
    const startApplication = () => {
        PermitStore.update(s => {
            s.applicationStep = 1;
        });
    }
    
    if(userDoc) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <h2 className="headingText ion-text-center">Welcome back, {userDoc.firstName}</h2>    
                   
                        <p className="ion-text-center">
                            <IonButton 
                                disabled={!permitTypes}
                                onClick={startApplication}
                                shape="round"
                                color="success"
                                >
                                Apply For Permit
                            </IonButton>
                        </p>
                    </IonCol>
                </IonRow>
                <ApplicationModal />
            </IonGrid>
        );
    } else {
        return (
            <IonLoading isOpen={true} />
        )
    }
};

export default Start;
