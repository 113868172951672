import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { UserStore } from "../../../store/UserStore";

const Step2 = () => {
    const step = UserStore.useState(s => s.onboardingStep);
    const userDoc = UserStore.useState(s => s.userDoc);

    const updateUserDoc = (e) => {
        UserStore.update(s => {
            s.userDoc = {...s.userDoc, lastName: e.target.value}
        })
    }
    return (
        <div className={ step === 2 ? 'steps' : 'ion-hide'}>
        <h2 className="ion-padding-horizontal onboardingTitles">Hi {userDoc.firstName}! What's your last name?</h2>
        <div style={{height: '20px'}} />
        <IonItem lines="none">
          <IonLabel className="onboardingLabels" position="stacked">Last Name</IonLabel>
          <IonInput 
            placeholder="Your last name" 
            className="onboardingInputs" 
            autocapitalize="on" 
            autofocus="true" 
            value={userDoc.lastName ? userDoc.lastName : null} 
            onIonChange={e => updateUserDoc(e)}
            />
        </IonItem>
        <IonButton  
          disabled={!userDoc.lastName ? true :
            false
          }
          className="ion-margin" 
          shape="round" 
          expand="block"
          onClick={() => {
            UserStore.update(s => {
              s.onboardingStep = 3;
            })
          }}
          >{'Continue'}</IonButton>
      </div>
  );
};

export default Step2;
