import { IonButtons, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from "../store/UserStore";
import { TownStore } from '../store/TownStore';

import Onboarding from '../components/Onboarding/Onboarding';
import Start from '../components/Start';
import BurnBan from '../components/BurnBan';
import Widgets from '../components/Widgets';
import headerIcon from '../assets/images/headerIcon.png';

const Account = () => {
  const userDoc = UserStore.useState(s => s.userDoc);
  const riskLevel = TownStore.useState(s => s.riskLevel);
  const burnBan = TownStore.useState(s => s.burnBan);
  const [ onboardingFlow, setOnboardingFlow ] = useState(false);
  
  useEffect(() => {
    // console.log(userDoc);
    if(userDoc && !userDoc.complete) {
      setOnboardingFlow(true);
    }
  }, [userDoc]);

    return (
      <IonPage mode="ios">
        <IonHeader className="whiteBar" color="primary">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton /><img src={headerIcon} className="headerIcon" alt="FirePermit.Online" />
            </IonButtons>
            <IonTitle>Account</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader  color="primary" collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Account</IonTitle>
            </IonToolbar>
          </IonHeader>
          {onboardingFlow ? 
            <Onboarding />
          : riskLevel === 5 || burnBan ? 
            <BurnBan />
          :
            <Start />
          }
          <IonGrid className="contentHolder">
            <Widgets />
          </IonGrid>
        </IonContent>
      </IonPage>
    );
};

export default Account;
