import { IonButton, IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import '../../Page.css';
/* Pullstate Store */
import { UserStore } from "../../../store/UserStore";
import { TownStore } from '../../../store/TownStore';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Step3 = () => {
    const step = UserStore.useState(s => s.onboardingStep);
    const townDoc = TownStore.useState(s => s.townDoc);
    const userDoc = UserStore.useState(s => s.userDoc);
    const [ consent, setConsent ] = useState(true);

    useEffect(() => {
      if(consent) {
        UserStore.update(s => {
          s.userDoc = {...s.userDoc, consent: true, optOut: false}
        })
      } 
      if(!consent) {
        UserStore.update(s => {
          s.userDoc = {...s.userDoc, consent: false, optOut: true}
        })
      }
    }, [consent])
    return (
        <div className={ step === 3 ? 'steps' : 'ion-hide'}>
            <h2 className="ion-padding-horizontal onboardingTitles">What's your phone number?</h2>
            <div style={{height: '20px'}} />
            <IonItem lines="none">
              <IonLabel className="onboardingLabels" position="stacked">Phone Number</IonLabel>
              <PhoneInput
                debounce
                country={townDoc && townDoc.countryCode}
                value={null}
                onChange={phone => UserStore.update(s => {
                  s.userDoc = {...s.userDoc, phone: '+' + phone}
                  })}
                inputClass="phoneInput onboardingInputs"
              />
            </IonItem>
            <div className="ion-padding">
              <p className="ion-text-left"><strong>How will we use your phone number?</strong></p>
              <p className="ion-text-left">Communications will come from <strong>{townDoc.deptName}</strong> and will be restricted to notifications of burn bans and other important notices. Your phone number will not be used for promotional or marketing purposes or shared with third parties.</p>
              <IonRadioGroup value={consent} onIonChange={e => setConsent(e.detail.value)}>
                <IonItem>
                  <IonLabel className="ion-text-wrap">I consent to receiving important alerts from {townDoc.fullName}</IonLabel>
                  <IonRadio slot="start" value={true} />
                </IonItem>

                <IonItem>
                  <IonLabel className="ion-text-wrap">I do not wish to receive important alerts from {townDoc.fullName}</IonLabel>
                  <IonRadio slot="start" value={false} />
                </IonItem>
              </IonRadioGroup>

            </div>
            <IonButton  
              disabled={!userDoc.phone || userDoc.phone.length < 12 ? true :
                false
              }
              className="ion-margin" 
              shape="round" 
              expand="block"
              onClick={() => {
                UserStore.update(s => {
                  s.onboardingStep = 4;
                })
              }}
              >{'Continue'}</IonButton>
          </div>
  );
};

export default Step3;
