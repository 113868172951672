import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from "../../../store/PermitStore";
import MapPositioner from '../../MapPositioner';
import { IonButton } from '@ionic/react';

const Step4 = () => {
    const step = PermitStore.useState(s => s.applicationStep);
    const application = PermitStore.useState(s => s.application);
  
    return (
        <div className={ step === 4 ? 'steps' : 'ion-hide'}>
            <h2 className="ion-padding-horizontal onboardingTitles">Application Step 4</h2>
            <div style={{height: '20px'}} />
            <div className="ion-padding">
            <MapPositioner />
            </div>
            <IonButton  
            disabled={!application.latitude}
            className="ion-margin" 
            shape="round" 
            expand="block"
            onClick={() => {
              PermitStore.update(s => {
                s.applicationStep = 5;
              })
            }}
            >Continue</IonButton>
            <div style={{height: '20px'}} />
        </div>
  );
};

export default Step4;
