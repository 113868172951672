import React, {useState, useEffect} from 'react';
import {IonCard, IonCardContent, IonCardTitle, IonCardSubtitle, IonCardHeader, IonSpinner} from '@ionic/react';
import WeatherIcon from 'react-icons-weather';
/* Pullstate Store */
import { TownStore } from "../store/TownStore";

const WeatherWidget = () => {  
    const townDoc = TownStore.useState(s => s.townDoc);
    const [ weather, setWeather ] = useState(null);
    const [ error, setError ] = useState(false);

    useEffect(() => {
      if(townDoc){
      const urlByCityId = `https://api.openweathermap.org/data/2.5/weather?lat=${townDoc.latitude}&lon=${townDoc.longitude}&appid=a6e74de9b3eb9f1dbcaf190984c0f0d5&units=${townDoc.units}`;
      const getWeather = () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(urlByCityId, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.cod <= 299) {
              setWeather(result);
              // console.log(result);
            } else {
              setWeather(null);
            }
          })
          .catch(error => {
            setWeather(null);
            console.log('error', error);
          });
      }
      getWeather();
      }
    }, [townDoc]);

    useEffect(() => {
      // console.log(weather);
      if(!weather) {
        setTimeout(() => {
          setError(true);
        }, 5000)
      }
    }, [weather]);

    if(townDoc && weather) {
      return (
        <IonCard mode="ios">
          <div className="iconHolder">
            <p className="ion-text-center">
              {weather && weather.weather[0] ? <WeatherIcon name="owm" iconId={`${weather.weather[0].id}`} flip="horizontal" rotate="90" /> 
              : null} <span className="temp">{weather ? weather.main.temp.toFixed(0) : null}&deg;</span></p>
          </div>
          <IonCardHeader>
            <IonCardSubtitle>Current</IonCardSubtitle>
            <IonCardTitle>Weather</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>  
              <p>Conditions are described as "{weather && weather.weather.length > 0 && weather.weather[0].description}" and the wind speed is currently {weather && weather.wind.speed}{townDoc && townDoc.units === 'imperial' ? 'mph' : 'kph'}</p>
          </IonCardContent>
        </IonCard>
      );
    } else {
      return (
      <IonCard mode="ios">
        <IonCardHeader>
          <IonCardSubtitle>Current</IonCardSubtitle>
          <IonCardTitle>Weather</IonCardTitle>
          <IonCardContent>
            {!error ? <IonSpinner /> : <p>There was an error retrieving the weather conditions.</p>} 
          </IonCardContent>
        </IonCardHeader>
      </IonCard>
      )
    }
};

export default WeatherWidget;
  