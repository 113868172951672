import { IonGrid, IonRow, IonCol, IonLoading, IonTitle, IonToolbar, IonHeader, IonContent, IonPage, IonButtons, IonMenuButton } from '@ionic/react';
import React from 'react';
import '../Page.css';
/* Pullstate Store */
import { UserStore } from "../../store/UserStore";
import Onboarding from '../Onboarding/Onboarding';
import headerIcon from '../../assets/images/headerIcon.png';
const NotCompleted = () => {
    const userDoc = UserStore.useState(s => s.userDoc);
    
    if(userDoc) {
        return (
            <IonPage mode="ios">
                <IonHeader className="whiteBar" color="primary">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton /><img src={headerIcon} className="headerIcon" alt="FirePermit.Online" />
                        </IonButtons>
                        <IonTitle>My Profile</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">My Profile</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid className="contentArea">
                        <IonRow>
                            <IonCol>
                                <Onboarding />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    } else {
        return (
            <IonLoading isOpen={true} />
        )
    }
};

export default NotCompleted;
