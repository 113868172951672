import { IonGrid, IonRow, IonCol, IonLoading, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { TownStore } from "../store/TownStore";


const BurnBan = () => {
    const townDoc = TownStore.useState(s => s.townDoc);
    
    if(townDoc) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonCard mode="ios" color="danger">
                        <IonCardHeader>
                            <IonCardTitle>Fire Ban</IonCardTitle>
                            <IonCardSubtitle>is currently in effect for our region.</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p>{townDoc.id} has instituted a fire ban. No burns are permitted. Please monitor this area for updates.</p>
                        </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        );
    } else {
        return (
            <IonLoading isOpen={true} />
        )
    }
};

export default BurnBan;
