import { IonLoading, IonPage } from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from "../store/UserStore";
// import { TownStore } from '../store/TownStore';

import Completed from '../components/Profile/Completed';
import NotCompleted from '../components/Profile/NotCompleted';

const Profile = () => {
  const userDoc = UserStore.useState(s => s.userDoc);
  // const townDoc = TownStore.useState(s => s.townDoc);

  // useEffect(() => {
  //   if(townDoc){
  //     console.log(townDoc);
  //   } 
  // }, [townDoc]);

  // useEffect(() => {
  //   console.log(userDoc);
  // }, [userDoc]);
  if(userDoc && !userDoc.complete) {
    return (
      <NotCompleted />
    );
  }
  if(userDoc && userDoc.complete) {
    return (
      <Completed />
    );
  } 
  if (!userDoc) {
    return (
      <IonPage>
        <IonLoading isOpen={true}/>
      </IonPage>
    )
  }
};

export default Profile;
