import React from 'react';
import './Page.css';
/* Pullstate Store */
import { TownStore } from '../store/TownStore';
import { PermitStore } from '../store/PermitStore';

import GoogleMapReact from 'google-map-react';
import FireMarker from './FireMarker/FireMarker';

const MapPositioner = () => {
    const application = PermitStore.useState((s) => s.application);
    const townDoc = TownStore.useState((s) => s.townDoc);

    function _onClick(obj) {
        // console.log(obj.x, obj.y, obj.lat, obj.lng, obj.event);
        PermitStore.update((s) => {
            s.application = { ...s.application, latitude: obj.lat, longitude: obj.lng };
        });
    }

    return (
        <>
            <div style={{ height: '350px', width: '100%' }}>
                <GoogleMapReact
                    onClick={_onClick}
                    bootstrapURLKeys={{
                        key: [townDoc.mapsAPI],
                        libraries: ['places'],
                    }}
                    getstureHandling='cooperative'
                    defaultCenter={{ lat: 43.17569125602508, lng: -79.45461769140168 }}
                    center={
                        application.latitude
                            ? { lat: application.latitude, lng: application.longitude }
                            : { lat: 43.17569125602508, lng: -79.45461769140168 }
                    }
                    defaultZoom={18}
                    zoom={application.latitude ? 18 : 13}
                    options={(maps) => ({
                        mapTypeControl: true,
                        mapTypeId: maps.MapTypeId.HYBRID,
                        scrollwheel: false,
                        getstureHandling: 'cooperative',
                    })}
                >
                    {application && application.latitude ? (
                        <FireMarker
                            lat={application.latitude}
                            lng={application.longitude}
                            text='My Marker'
                            play={true}
                            color='orange'
                        />
                    ) : null}
                </GoogleMapReact>
            </div>
            <p>
                It's important to know where the burn will take place on your property. Please tap
                or click on the map to reposition the flame to indicate where you will conduct the
                burn.
            </p>
        </>
    );
};

export default MapPositioner;
