import { IonGrid, IonRow, IonCol, IonLoading, IonTitle, IonToolbar, IonHeader, IonContent, IonPage, IonButtons, IonMenuButton, IonIcon, IonButton, IonCardContent, IonCard, IonAlert} from '@ionic/react';
import React, { useState } from 'react';
import '../Page.css';
/* Pullstate Store */
import { UserStore } from "../../store/UserStore";
import { createOutline, warningOutline } from 'ionicons/icons';
import OnboardingModal from '../Onboarding/OnboardingModal';
import firebaseApp from '../../firebase';
import headerIcon from '../../assets/images/headerIcon.png';

const Completed = () => {
    const userDoc = UserStore.useState(s => s.userDoc);
    const [ showAlert, setShowAlert ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const startOnboaring = () => {
        UserStore.update(s => {
            s.onboardingStep = 1;
        })
    }
    const promptUser = () => {
        setShowAlert(true);
    }

    const deleteAccount = () => {
        setIsLoading(true);
        const deleteUser = firebaseApp.functions().httpsCallable('deleteUser');
        const user = userDoc.id;
        if(user) {
        deleteUser({ userId: user })
        .then(() => {
            setTimeout(() => {
                setIsLoading(false);
                window.location.reload();
            }, 5000);
        });
        } else {
            setIsLoading(false);
            console.log('missing userid')
        }
    }

    if(userDoc) {
        // console.log(userDoc);
        return (
            <IonPage mode="ios">
                <IonLoading isOpen={isLoading} />
                <IonHeader className="whiteBar" color="primary">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton /><img src={headerIcon} className="headerIcon" alt="FirePermit.Online" />
                        </IonButtons>
                        <IonTitle>My Profile</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">My Profile</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid className="contentArea">
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <h2 className="ion-padding-horizontal ion-text-center onboardingTitles">{userDoc.firstName} {userDoc.lastName}</h2>
                                        <p className="profileDeets ion-text-center">Email Address</p>
                                        <p className="ion-text-center">{userDoc.email}</p>

                                        <p className="profileDeets ion-text-center">Phone</p>
                                        <p className="ion-text-center">{userDoc.phone}</p>
                                        <p className="profileDeets ion-text-center">Address</p>
                                        <p className="ion-text-center">{userDoc.fullAddress}</p>
                                        <div className="ion-padding-vertical">
                                            <IonButton expand="block" shape="round" fill="outline" onClick={startOnboaring}><IonIcon slot="start" icon={createOutline} /> Edit</IonButton>
                                        </div>
                                        <p className="ion-text-center">Please note that email addresses cannot be changed at this time.</p>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p className="ion-text-center">
                                    <IonButton fill="clear" color="danger" onClick={promptUser}><IonIcon icon={warningOutline} />
                                    &nbsp;&nbsp; Delete my account</IonButton>
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <OnboardingModal />
                </IonContent>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Please confirm'}
                    message={'This action cannot be undone. If you delete your account, you will no longer have access to your existing permits and information. Are you sure?'}
                    buttons={[
                        {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                            handler: blah => {
                                console.log('Confirm Cancel: blah');
                            }
                        },
                        {
                        text: 'Yes, please delete',
                            handler: () => {
                                deleteAccount();
                            }
                        }
                    ]}
                    />
            </IonPage>
        );
    } else {
        return (
            <IonLoading isOpen={true} />
        )
    }
};

export default Completed;
