import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { UserStore } from "../../../store/UserStore";

const Step1 = () => {
    const step = UserStore.useState(s => s.onboardingStep);
    const userDoc = UserStore.useState(s => s.userDoc);

    const updateUserDoc = (e) => {
        UserStore.update(s => {
            s.userDoc = {...s.userDoc, firstName: e.target.value}
        })
    }
    return (
      <div className={step === 1 ? null : 'ion-hide'}>
        <h2 className="ion-padding-horizontal onboardingTitles">What's your first name?</h2>
        <div style={{height: '20px'}} />
        <IonItem lines="none">
          <IonLabel className="onboardingLabels" position="stacked">First Name</IonLabel>
          <IonInput 
            type="text" 
            autocapitalize="on"
            placeholder="Your first name" 
            className="onboardingInputs"
            enterkeyhint="next"
            inputmode="text"
            pattern="text"
            value={userDoc.firstName ? userDoc.firstName : null}
            onIonChange={e => updateUserDoc(e)}
          />
        </IonItem>
        <IonButton  
          disabled={!userDoc.firstName ? true :
            false
          }
          className="ion-margin" 
          shape="round" 
          expand="block"
          onClick={() => {
            UserStore.update(s => {
              s.onboardingStep = 2;
            })
          }}
          >{'Continue'}</IonButton>
      </div>
  );
};

export default Step1;
