import { IonRow, IonCol } from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { TownStore } from "../store/TownStore";

import Announcements from './Announcements';
import WeatherWidget from './WeatherWidget';
import RiskGauge from './RiskGauge';


const Widgets = () => {
    const townDoc = TownStore.useState(s => s.townDoc);
    
    if(townDoc) {
        return (
            <>
            {townDoc.widgetsOn ? 
                <>
                    <IonRow>
                        { townDoc.riskGaugeOn ? <IonCol>
                            <RiskGauge/>
                        </IonCol> : null}
                        { townDoc.weatherOn ?
                        <IonCol>
                            <WeatherWidget />
                        </IonCol>
                        : null }
                    </IonRow>
                    <IonRow>
                        {townDoc.announcementsOn ? 
                        <IonCol>
                            <Announcements />
                        </IonCol>
                        : null}
                    </IonRow>
                </>
            : null }
            </>
        )
    } else {
        return null
    }
};

export default Widgets;
