import { IonButtons, IonButton, IonHeader, IonToolbar, IonModal, IonIcon, IonTitle } from '@ionic/react';
import React from 'react';
import '../Page.css';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import ApplicationSteps from './ApplicationSteps';
import { PermitStore } from '../../store/PermitStore';

const ApplicationModal = () => {
    const step = PermitStore.useState(s => s.applicationStep);

    const close = () => {
        PermitStore.update(s => {
            s.applicationModal = false;
            s.applicationStep = 0;
        })
    }
    const updateStep = (inc) => {
        if(inc !== 0) {
            PermitStore.update(s => {
                s.applicationStep = s.applicationStep + inc;
            })
        } else {
            PermitStore.update(s => {
                s.applicationStep = 0;
            })
        }
    }

    // useEffect(() => {
    //     console.log(step);
    // }, [step]);
    
    return (
        <IonModal 
            mode="ios"
            isOpen={step > 0}
            onDidDismiss={close}
            cssClass="onboardModal"
        >
            <IonHeader>
                <IonToolbar>
                    {step > 1 ? 
                    <IonButtons slot="start">
                        {step !== 7 ? 
                        <IonButton onClick={() => updateStep(-1)}><IonIcon icon={chevronBackOutline} slot="start"/> Back</IonButton>
                        : null}
                    </IonButtons>
                    : null}
                    <IonTitle>Step {step}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton 
                            onClick={() => {
                                updateStep(0);
                                close();
                            }}
                        ><IonIcon icon={closeOutline} slot="icon-only"/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <ApplicationSteps />
        </IonModal>
  );
};

export default ApplicationModal;
