import { IonButton } from '@ionic/react';
import React from 'react';
import { UserStore } from '../../../store/UserStore';
import AddressPicker from '../../AddressPicker';
import '../../Page.css';

const Step4 = () => {
    const step = UserStore.useState(s => s.onboardingStep);
    const userDoc = UserStore.useState(s => s.userDoc);

    return (
      <div className={ step === 4 ? 'steps' : 'ion-hide'}>
        <h2 className="ion-padding-horizontal onboardingTitles">Find your address</h2>
          <div style={{height: '20px'}} />
          <AddressPicker context="onboarding" />
          <div className="ion-padding-horizontal">
            <p className="ion-text-left"><strong>How will we use your address?</strong></p>
            <p className="ion-text-left">You address will be used as your default permit address. You will be able to change the address on your burn permit applications, if necessary.</p>
          </div>
          <IonButton  
            disabled={!userDoc.address ? true :
              false
            }
            className="ion-margin" 
            shape="round" 
            expand="block"
            onClick={() => {
              UserStore.update(s => {
                s.onboardingStep = 5;
              })
            }}
            >{'Continue'}</IonButton>
      </div>
    );
};

export default Step4;
