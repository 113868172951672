import React, { useState } from 'react';
import '../../Page.css';

import { firestore } from '../../../firebase';
/* Pullstate Store */
import { UserStore } from "../../../store/UserStore";
import { IonButton, IonLoading } from '@ionic/react';

const Step5 = () => {
    const step = UserStore.useState(s => s.onboardingStep);
    const userDoc = UserStore.useState(s => s.userDoc);
    const user = UserStore.useState(s => s.user);
    const [ isLoading, setIsLoading ] = useState(false);

    const saveUser = () => {
      setIsLoading(true);
      firestore.collection('users').doc(user.uid).update({
        complete: true,
        firstName: userDoc.firstName,
        lastName: userDoc.lastName,
        phone: userDoc.phone,
        address: userDoc.address,
        fullAddress: userDoc.fullAddress,
        postal: userDoc.postal,
        province: userDoc.province,
        municipality: userDoc.municipality,
        consent: userDoc.consent,
        optOut: userDoc.optOut
      })
      .then(() => {
        setIsLoading(false);
        user.updateProfile({ 
          displayName: userDoc.firstName + ' ' + userDoc.lastName,
        })
        .then(() => {
          console.log('updated user');
          setTimeout(() => {
            window.location.reload()
          }, 1500);
        })
      })
      .catch((error) => {
        console.log('update user failed', error)
      });
    }
    return (
      <>
        <div className={ step === 5 ? 'steps' : 'ion-hide'}>
            <h2 className="ion-padding-horizontal onboardingTitles">Please confirm your details</h2>
            <div style={{height: '20px'}} />
            <div className="ion-padding">
                <p className="ion-text-center deets">{userDoc.firstName} {userDoc.lastName}</p>
                <p className="ion-text-center deets">{userDoc.phone}</p>
                <p className="ion-text-center deets">{userDoc.fullAddress}</p>
            </div>
            <IonButton 
                className="ion-margin" 
                shape="round" 
                expand="block"
                onClick={() => saveUser()}
                >{'Save My Profile!'}</IonButton>
        </div>
        <IonLoading isOpen={isLoading} />
      </>
    );
};

export default Step5;
