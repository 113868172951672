import React from 'react';
import './CustomMarker.css';
import { IonIcon } from '@ionic/react';
import { flameSharp } from 'ionicons/icons';

const FireMarker = (props) => {

    return (
      <div>
        <IonIcon icon={flameSharp} className="flamer" color="warning" size=""/>
      </div>
    );
  };

  export default FireMarker;