import { IonCol, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './Page.css';
import logo from '../assets/images/logo.png';

const NoNetwork = () => {
    
    return (
      <IonPage mode="ios">
        <IonHeader>
          <IonToolbar translucent="true">
            <IonTitle>No Network Connection</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="contentArea ion-padding-horizontal">
            <IonRow>
              <IonCol>
                <IonImg style={{ width:'250px', margin: '0 auto' }} className="ion-padding" src={logo} />  
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
              <h2 className="ion-text-center onboardingTitles">No internet connection was detected.</h2>
              <p className="ion-text-center">Please refresh this page once you have a reliable internet connection.</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
};

export default NoNetwork;
