import ReactSpeedometer from 'react-d3-speedometer';
import React from 'react';
/* Pullstate Store */
import { TownStore } from '../store/TownStore';
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
} from '@ionic/react';

const RiskGauge = () => {
    const townDoc = TownStore.useState((s) => s.townDoc);

    const labels = [
        {
            text: 'Low',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
        {
            text: 'Moderate',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
        {
            text: 'High',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
            fontSize: '19px',
        },
        {
            text: 'Very High',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
        {
            text: 'Burn Ban',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
    ];
    return (
        <IonCard>
            <div
                style={{
                    width: '100%',
                    height: '150px',
                    margin: '20px auto 25px auto',
                }}
            >
                <div className='ion-text-center'>
                    <ReactSpeedometer
                        paddingVertical={10}
                        maxValue={5}
                        value={townDoc ? townDoc.riskLevel - 0.5 : 0}
                        needleColor={'#5260ff'}
                        needleHeightRatio={0.45}
                        segments={5}
                        // customSegmentStops={[0, 1.5, 2.5, 3.5, 5.0]}
                        segmentColors={[
                            '#5260ff',
                            // "#3dc2ff",
                            '#2dd36f',
                            '#ffc409',
                            '#ff6600',
                            '#ff0000',
                        ]}
                        customSegmentLabels={labels}
                        currentValueText={`${townDoc && townDoc.riskLevel}`}
                    />
                </div>
            </div>
            <IonCardHeader>
                <IonCardSubtitle>Current</IonCardSubtitle>
                <IonCardTitle>Fire Risk Level: {townDoc && townDoc.riskLevel}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {townDoc && townDoc.fireban ? (
                    <p>
                        Local conditions pose extreme fire risk. Serious fires can start easily,
                        spread quickly, burn intensely, and be very difficult to control. NO OPEN
                        AIR FIRE PERMITTED.
                    </p>
                ) : townDoc && townDoc.riskLevel === 1 ? (
                    <p>
                        Weather and ground conditions pose a low risk to accidental fire spread.
                        Please burn responsibly.
                    </p>
                ) : townDoc && townDoc.riskLevel === 2 ? (
                    <p>
                        Use care when open air burning. Local conditions pose potential for fires to
                        spread accidentally, especially on dry or windy days.
                    </p>
                ) : townDoc && townDoc.riskLevel === 3 ? (
                    <p>
                        Use caution when open air burning. Dry local conditions could allow fires to
                        spread easily and be difficult to control.
                    </p>
                ) : townDoc && townDoc.riskLevel === 4 ? (
                    <p>
                        Use extreme caution. Fires could spread quickly and beyond ability to
                        control. Postponing open air burning is recommended.
                    </p>
                ) : townDoc && townDoc.riskLevel === 5 ? (
                    <p>
                        Local conditions pose extreme fire risk. Serious fires can start easily,
                        spread quickly, burn intensely, and be very difficult to control. NO OPEN
                        AIR FIRE PERMITTED.
                    </p>
                ) : null}
            </IonCardContent>
        </IonCard>
    );
};

export default RiskGauge;
