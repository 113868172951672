import { 
  IonContent,
  IonPage,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonRow,
  IonCol,
  IonText, 
  IonGrid,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonImg,
} from '@ionic/react';
import React, { useState } from 'react';
import { auth } from '../firebase';
import logo from '../assets/images/logo.png';

const Forgot = ({history}) => {

  const [email, setEmail] = useState(``);
  const [error, setError] = useState(``);

  const sendPasswordResetEmailHandler = (event, email) => {
    event.preventDefault();
    setError(``);

    if (email.length < 4) {
      setError(`Name must be at least 4 characters.`);
    }

    if (error === ``) {
      auth.sendPasswordResetEmail(email)
      .then(() => {
        console.log('Reset email sent');
        history.push('/login', {direction: 'none'})
      })
      .catch((e) => {
        setError(e.message);
      });
    }
  };

  return (
    <IonPage mode="ios">
      
      <IonContent>
        <IonGrid className="contentArea ion-padding-horizontal">
          <IonRow>
            <IonCol>
              <div style={{height: 50}} />
              <IonImg className="ion-padding" src={logo} />  
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard mode="ios">
                <IonCardHeader className="ion-margin-top">
                  <IonCardTitle>
                    Password Reset
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Enter your email below and we'll send you instructions to reset your password.
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
            
                <IonList>
                <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput value={email} placeholder="Enter Email" onIonChange={e => setEmail(e.detail.value)}></IonInput>
                  </IonItem>
                </IonList>
                <br/>
                <IonButton 
                  expand="full"
                  color="primary"
                  shape="round"
                  onClick={
                    event => {
                      sendPasswordResetEmailHandler(event, email);
                    }
                  }
                >Send Reset Instructions</IonButton>
               
                <IonRow>
                  <IonCol>
                  {error !== '' && (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                      {error}
                      </p>
                    </IonText>
                  )}
                  </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                     <IonButton routerLink="/login" color="primary" fill="clear" expand="block">Login</IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton routerLink="/register" color="primary" fill="clear" expand="block">Register</IonButton>
                    </IonCol>
                </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Forgot;
