import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from "../store/UserStore";
import { logInOutline } from 'ionicons/icons';


const AccountButton = (props) => {
    const user = UserStore.useState(s => s.user);

    return (
        <IonButton shape="round" color={props.color ? props.color : "success"} routerLink={user ? '/account' : '/login'}>
            {user ? 'Go to My Account' : props.icon ? <IonIcon icon={logInOutline} slot="icon-only" /> : 'Log In/Register'}
        </IonButton>         
    );
};

export default AccountButton;
